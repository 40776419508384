<template>
  <div class="blogs">
    <h1 class='container'>Archive</h1>
    <div class='container content'>
      <div class='tiles'>
        <router-link v-for="(blog, key) in blogs" :to="`/blog/2015/${key}`" :key="key" class='tile'>
          <h3>{{ blog.title }}</h3>
          <div v-html="blog.content"></div>
          <p>Read More</p>
        </router-link>            
      </div>
      <div>
        <div>
        <Contact />
        <div>
            <h2>Services</h2>
            <ul>
              <li v-for="(service, key) in services" :key="key">
                <router-link :to="`/blog/pt-services/${key}`">
                  {{ service.title }}
                </router-link>

              </li>
            </ul>
          </div>        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
export default {
  name: 'Services',
  components: { 
    Contact
  },
  computed: {
    services() {
      return this.$store.getters.services
    },
    blogs() {
      return this.$store.getters.blogs
    }
  }
}
</script>

<style scoped lang='scss'>
.blogs {
  padding-bottom: 50px;
  h1 {
    font-family: "Merriweather", serif;
    margin-bottom: 10px;
  }

  .tile {
    color: black;
    padding: 0px;
    div, p {
      color: #7f7f7f;
    }

    p:hover {
      color: #26bac5 !important;
    }

    h3 {
      font-family: "Merriweather", serif;
      &:hover {
        color: #26bac5 !important;
      }
    }

  }


  h5 {
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 300;
    color: #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }

  img {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    div:nth-child(2) {
      div {
        border-radius: 5px;
        background-color: #2F3439;
        padding: 15px;


        h2 {
          color: white;
          font-family: "Merriweather", serif;
          font-size: 1.2em;
        }

      }

        ul {
          margin-left: 0px;
          padding-left: 0px;
          list-style: none;

          li {
            margin-bottom: 5px;
            a {
              color: #7f7f7f !important;
              
              &:hover {
                color: #26bac5 !important;
              }
            }
          }
        }      
    }
  }
}

</style>
